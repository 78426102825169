/* MAIN styles */
.TimeslotsTable {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px rgb(0 0 0 /0.25);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  max-width: 100% !important;
  min-height: 142px;
  padding: 1em 10px 10px 10px;
  text-align: center;
}
.TimeslotsTable .flex-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif !important;
  gap: min(40px, 5vw);
  justify-content: center;
  max-width: 100% !important;
  width: 95%;
}
.TimeslotsTable.mobile .flex-wrapper {
  flex-direction: column;
  width: 100%;
}
.TimeslotsTable.mobile .flex-wrapper > * {
  margin: 2vh;
}

/* SECTION TITLE styles */
.TimeslotsTable .admin-title {
  color: #65676b;
  font-family: "Open Sans";
  font-weight: 700;
  padding: 0.5vh 1.5vh;
  width: 100%;
}

.TimeslotsTable h3 {
  font-size: 1.25em;
  margin: 0 auto 1em auto;
  text-align: center;
  width: fit-content;
}

/* BUTTON styles */
.TimeslotsTable .cancel-all {
  background-color: #c84e00;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  height: fit-content;
  margin-bottom: 0;
  padding: 8px 10px 10px 8px;
  width: 100%;
}
.TimeslotsTable .cancel-button {
  background: #c84e00;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  height: fit-content;
  margin-bottom: 0;
  padding: 8px 10px 10px 8px;
  width: 30%;
}
.TimeslotsTable .cancel-button:hover {
  background: #8f3700;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}
.TimeslotsTable .submit-button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  height: fit-content;
  margin-bottom: 0;
  padding: 8px 10px 10px 8px;
  width: 30%;
}
/* .TimeslotsTable .message .input button:hover, */
.TimeslotsTable .submit-button:hover {
  background: #012169;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}

.TimeslotsTable table tr:first-child th {
  width: 50%;
}

/* TABLE styles */
#actual-table {
  width: 80%;
}

/* PREVIEW styles */
.TimeslotsTable .previews {
  border: 1px solid grey;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgb(0 0 0 /0.5);
  padding: 10px;
}

/* VISICON styles */
.TimeslotsTable .vis-icon:hover {
  cursor: pointer;
}
.TimeslotsTable .vis-icon > * {
  margin: auto 0;
  vertical-align: middle;
}

/* NOSLOT styles */
.TimeslotsTable .noslot {
  align-self: center;
}

.no-date-selec {
  text-align: center;
}
.AC-slxn-wrapper {
  min-height: fit-content;
}
