.UserHome {
  background-color: #e2e6ed;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;
  text-align: center;
  width: 100vw;
  flex-grow: 1;
  display: block;


}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* loading */
.wheel{
  transform:translate(0%, 400%)
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loading ends */
.ScheduleStatus {
  flex-grow: 1;
}
.UserHome.mobile h1 {
  margin: 0 auto;
  overflow-wrap: break-word;
  margin-top: 20px;
  white-space: normal;
  width: 75vw;
}
.UserHome .UH-flex-wrapper {
  animation: fadeIn .5s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
}
.UserHome .UH-flex-wrapper.no-stu {
  justify-content: center;
}
.UserHome .UH-flex-wrapper.no-stu > * {
  margin: 10px;
}
.UserHome.mobile .UH-flex-wrapper.no-stu {
  align-items: center;
  flex-direction: column;
}
.UserHome.mobile .UH-flex-wrapper {
  align-items: center;
  flex-direction: column;
}
/* !TODO: make width !=27 when mobile??  */
.UserHome .UH-flex-wrapper > * {
  width: 27vw;
}
.UserHome.mobile .UH-flex-wrapper > * {
  width: unset;
}
.UserHome .UH-col1-wrapper {
  display: flex;
  flex-direction: column;
  /* width: 27vw; */
  /* margin-left: 5vw; */
  min-width: 22vw;
  text-align: left;
}
.UserHome .UH-col1-wrapper h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: initial !important;
  padding: 5px 5px 5px 10px;
}
.UserHome.mobile .UH-col1-wrapper {
  margin: 0 auto;
  margin-bottom: 5vh;
  width: 75vw;
}
