/* MAIN styles */
.student-preview {
  margin: 0 auto;
  max-width: 90%;
  overflow-x: scroll;
}
.student-preview h3 {
  font-size: 1.25em;
  text-align: center;
}
.student-preview .AC-slot,
.student-preview .hour-select,
.student-preview .timeslot-select {
  margin: 5px;
}
.student-preview .hour-select,
.student-preview .timeslot-select {
  align-items: center;
  display: flex;
  justify-content: center;
}
.student-preview .nobody-scheduled {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* DROPDOWN styles */
select {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  color: #555;
  font-size: 16px;
  padding: 8px 12px;
}

/* PREVIEW TABLE styles */
#preview-table {
  font-size: 1em;
  width: 100%;
  min-width: fit-content;
  table-layout: fixed;
  word-wrap: break-word;
}
#preview-table .status-cell > * {
  background-color: transparent;
  color: #969696;
  text-align: center;
}
#preview-table .MuiSvgIcon-root {
  height: 1.3em;
  vertical-align: middle;
  width: 1.3em;
}
#preview-table .status-cell {
  align-content: center;
  height: 100%;
  text-align: center;
  width: 100%;
}
#preview-table .status-cell > *:hover {
  cursor: pointer;
}
#preview-table .arr-arrived {
  color: #a1b70d;
  text-shadow: 0 0 2px #a1b70d;
}
#preview-table .mis-missed {
  color: #c84e00;
  text-shadow: 0 0 2px #c84e00;
}
#preview-table #tc-student {
  min-width: 90px;
  width: 90px; /* so col doesn't shrink */
  overflow-wrap: break-word;
}
#preview-table #tc-boxnum {
  width: 62px;
}
#preview-table #tc-numpkg {
  width: 40px;
}
#preview-table th,
#preview-table td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
#preview-table .button-td {
  padding: 0;
}
#preview-table th {
  background-color: #f2f2f2;
}
#preview-table tr:hover {
  background-color: #f5f5f5;
}
#preview-table tr:first-child th {
  border-top: none;
}
#preview-table tr:last-child td {
  border-bottom: none;
}
#preview-table #tc-status {
  width: 82px;
}
#preview-table #tc-timeslot {
  width: 42px;
}
#preview-table .sp-stat-col {
  width: 40px;
}
#table-scroll {
  font-style: italic;
  text-align: center;
}

/* ICON styles  */
.csv-dl {
  align-items: center;
  border-top: 1px dashed grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#preview-student .AC-slot,
#preview-student .hour-select,
#preview-student .timeslot-select {
  margin: 5px;
}
#preview-student .hour-select,
#preview-student .timeslot-select {
  align-items: center;
  display: flex;
  justify-content: center;
}
#preview-student .no-sched {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#nobody-icon {
  color: grey;
  font-size: 3em;
}