/* MAIN PHUB styles */
.PHub {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 6px rgb(0 0 0 /0.25);
  min-height: 50vh;
  overflow: hidden;
  width: 28vw;

  /* don't stretch to accommodate packages */
}
.PHub * {
  color: #fff;
  font-family: "Open Sans";
}
.PHub h2 {
  background-color: #993399;
  font-size: 1.25em;
  font-weight: 700;
  margin: 0;
  padding: 1.5vh;
}
.PHub.mobile {
  margin-bottom: 5vh;
  width: 75vw;
}
/* PHUB Content styles */
.PHub .PHubContent {
  background-color: #fff;
  margin-left: 5%;
  margin-right: 5%;
  max-height: 60vh;
  overflow-y: scroll;
  padding-top: 5px;
}
/* PHUB Content scrollbar styles */
.PHubContent::-webkit-scrollbar {
  width: 0;
}
.PHubContent::-webkit-scrollbar-track {
  background-color: transparent;
}
.PHubContent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Package:first-of-type {
  margin-top: 1.5vh;
  /* add top margin for first package */
}

.nopkg-msg {
  font-style: italic;
  margin-top: 1.5vh;
  text-align: center;
  color: rgb(0 0 0 /0.5);
}

.loading {
  color: rgba(0 0 0 /0.5);
  font-style: italic;
  font-weight: 400;
  padding: 15px;
}
