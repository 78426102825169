@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap";
.FAQ {
  box-sizing: border-box;
}
.FAQ .header-wrapper {
  position: sticky;
}
.FAQ .content-wrapper {
  background-color: #e2e6ed;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  min-height: 100vh;
  padding-bottom: 7vh;
  width: 100vw;
}
.FAQ.mobile .content-wrapper {
  display: flex;
  flex-direction: column;
}
.FAQ .dropper {
  color: #005587;
  font-size: 1.25em;
  padding-right: 10px;
}
.FAQ ul {
  display: flex !important;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
}
.FAQ.mobile ul,
FAQ.mobile .answer {
  max-width: 70vw;
}
.FAQ .question-wrapper {
  align-self: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 15px;
  padding: 15px;
  text-align: left;
  width: 75vw;
}
.FAQ .question-wrapper:last-of-type {
  margin-bottom: 0;
}
.FAQ.mobile .question-wrapper {
  justify-content: center;
}
.FAQ .question {
  font-weight: 700;
  margin: 0;
  padding: 5px;
}
.FAQ,
question:hover {
  cursor: pointer;
}
.FAQ.mobile .question {
  align-items: center;
  display: flex;
}
.FAQ .answer {
  margin: 0;
  padding-bottom: 5px;
  padding-left: 5px;
}
.FAQ p {
  color: #000;
}

.FAQ .paragraph {
  margin : 0 0 7px 0;
}

.FAQ h2 {
  color: rgb(0 0 0 /.6);
  font-weight: 700;
  font-size: 1.25em;
  margin-left: 10px;
}
h2.page-subtitle-center{
  text-align: center;
}