.Header.mid {
  justify-content: space-between;
}
.duke-wrapper {
  background-color: #012169;
  color: #fff;
  display: flex;
  height: 90px;
  white-space: nowrap;
  z-index: 2;
}

.duke-wrapper.mid {
  flex-grow: 1;
}
.header-sub {
  align-self: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25em;
  padding: 20px 30px 20px 0;
}
.header-sub:hover,
.header-duke:hover {
  cursor: pointer;
}
.header-sub.mobile {
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.5em;
}
.header-duke {
  align-self: center;
  font-family: "EB Garamond";
  font-size: 2em;
  padding: 20px 15px 20px 20px;
  text-align: center;
}

.header-duke::after {
  border-right: 1px solid #fff;
  content: "";
  padding: 5px;
}

.logout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: auto 16px;
  min-width: fit-content;
  padding: 5px;
  margin-right: 20px;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 700;
  font-family: "Open Sans";
  color: white;
  font-size: 20;
  margin-right: 5;
  text-transform: unset !important;
  font-size: 20px !important;
}

.logout.open {
  margin: 8px 16px;
  text-align: right;
  justify-content: space-between;
  text-transform: none;
  font-size: 20px;
}

.logout:hover {
  cursor: pointer;
  background-color: #1d6363;
  border-bottom: 2px solid #fff;
  text-transform: none;
  font-size: 20px;
}

.logout > * {
  text-decoration: none !important;
  font-weight: 700 !important;
  font-family: "Open Sans" !important;
  color: white !important;
  font-size: 20 !important;
  margin-right: 5 !important;
  text-transform: none !important;
  font-size: 20px !important;
}
