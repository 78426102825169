.LineStatus {
  font-family: "Open Sans";
}
.update-l {
  color: #59595F;
  font-size: .75em;
  margin-top: 5px;
  padding-left: 15px;
}
.LineStatus h2 {
  margin-bottom: 5px;
}
.LineStatus .MuiSvgIcon-root {
  height: 1.75em !important;
  width: 1.75em !important;
}
.circle.selected {
  border: 5px solid #000;
}
#status-circle {
  color: #c84e00;
  font-size: 2em;
  margin-top: 10px;
}
.status-box {
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  margin: 5px;
  min-height: fit-content;
  min-width: fit-content;
  overflow-wrap: normal;
  padding: 10px 10px;
}
.circle {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  font-size: 600px;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  width: 40px;
}
.LineStatus .status-box p {
  color: #000;
  font-size: 1.25em;
  font-weight: 400;
  margin: auto 0;
  margin-left: 10px;
  text-align: left;
}
.LineStatus.mobile .status-box {
  height: fit-content;
  padding: 15px;
}
.status-selector {
  align-items: center;
  display: flex;
}
.red {
  background-color: #c84e00;
  font-size: 100px;
}
.yellow {
  background-color: #ffd960;
}
.green {
  background-color: #a1b70d;
}
.grey {
  background-color: grey;
}
.checkmark {
  color: #fff;
  font-size: 18px;
}
.LineStatus button {
  cursor: pointer;
  margin-top: 10px;
  padding: 10px 20px;
}
.mod-wrapper {
  background-color: rgb(233 215 215);
  padding: 15px;
}
