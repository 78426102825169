.Scheduler-wrapper {
  background-color: #e2e6ed;
  height: 100%;
  padding-bottom: 5vh;
  width: 100%;
}
.Scheduler-wrapper.mobile {
  height: 100%;
  margin: 0;
  padding-bottom: 10vh;
  width: 100%;
}
h2 {
  /* color: #012169; */
  font-weight: 700;
  font-size: 1.25em;
  margin-left: 10px;
  font-family: "Open Sans";
}
h2.page-subtitle-center {
  text-align: center;
}

h2.page-subtitle-center-alert {
  text-align: center;
  font-size: 1em;
  word-wrap: break-word;
  padding: 5px 25px 0px 0px;
  color: rgba(0,0,0,.5) !important;

}

h2.page-subtitle-center-alert.mobile {
  text-align: center;
  font-size: 0.9em !important;
  word-wrap: break-word;
  padding: 5px 25px 0px 0px;
  color: rgba(0,0,0,.5) !important;

}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1vh;
  padding: 12px 29px 30px;
}

.back-button {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: larger;
  transform: scale(1.5);
  /* color: rgb(0 0 0 /0.6); */
}
