.Footer {
  align-items: center;
  background-color: #012169;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 700;
  justify-content: space-between;
  padding: 10px;
}
.Footer.mid {
  flex-direction: column;
  justify-content: flex-start;
}
.Footer > * {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Footer.mid > * {
  width: unset;
  text-align: center;
}

.Footer .email {
  text-align: center;
}
.Footer.mid .email,
.Footer.mid .phone {
  text-align: left;
}
.Footer .phone {
  text-align: right;
}
.Footer a {
  align-self: center;
  color: #fff;
  padding: 0;
}
.Footer .contact-icon {
  margin-right: 4px;
}
