div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* CONTENT styles */
#main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#main-content > * {
  flex-grow: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* PAGE TITLE styles */
.page-title {
  animation: fadeIn 0.5s;
  color: #012169;
  font-family: "EB Garamond", serif;
  font-size: clamp(16px, 10vw, 3em);
  font-weight: 700;
  text-align: center;
  white-space: normal;
}
.page-title-loading {
  animation: fadeIn 0.5s;
  color: #012169;
  font-family: "EB Garamond", serif;
  font-size: 3em;
  font-weight: 700;
  opacity: 15%;
  text-align: center;
  white-space: normal;
}

/* SCREEN READER ONLY styles */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
