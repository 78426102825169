.Address {
  font-family: "Open Sans", sans-serif;
  margin-top:5px;
}
.address-text {
  background-color: #fff;
  min-width: fit-content;
  word-wrap: nowrap;
  border-radius: 16px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  margin-left: 5px;
  padding: 10px;
}

.Address h2 {
  margin-bottom: .5vh;
}

.address-text p {
  margin: 0;
}
#how-to{
margin-left: 10px;
}
