.staff-member {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 30%;
  padding: 0px 10px 10px 10px;

  justify-content: center;
}
.staff-member h3 {
  text-align: center;
}
.staff-member p {
  max-width: 250px;
}

.person {
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgb(0 0 0 /.25);
  height: 200px;
  min-width: 200px;
  object-fit: contain;
  overflow: hidden;
  width: 200px;
}

.person-info {
  margin-left: 20px;
}
