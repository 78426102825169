.BoxNum {
  font-family: "Open Sans";
}
.BoxNum.mobile {
  justify-content: center;
}
.box-box {
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  margin: 5px auto;
  text-align: center;
}
.mobile .box-box {
  height: fit-content;
  padding: 10px 20px;
}
.box-box p {
  color: #00539b;
  font-size: 1.75em;
  font-weight: 700;
  margin: 0;
}
