.Scheduler {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 /0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  min-height: 40vh;
  padding-bottom: 2vh;
  width: 70vw;
}
.Scheduler h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
.Scheduler #timeslot-h2 {
  margin-bottom: 0;
}
.Scheduler.mid {
  align-items: center;
  flex-direction: column;
  width: min(95vw, 400px);
}
.Scheduler.mid > * {
  width: 90%;
}
.Scheduler > * {
  flex: 1;
  font-family: "Open Sans", sans-serif !important;
  max-width: 95%;
  min-height: 40vh;
}
.Scheduler .packages {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}
.Scheduler .pkg-wrapper {
  align-items: center;
  background-color: #00539b;
  border-radius: 10px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 1vh;
  min-height: 8vh;
  overflow-wrap: break-word;
  padding: 6px;
  text-align: left;
  width: 80%;
}
.Scheduler .pkg-wrapper:last-of-type {
  margin-bottom: 3vh;
}
.Scheduler .pkg-wrapper p {
  color: #fff;
  margin: 0;
  word-break: break-all;
}
.Scheduler .picker {
  align-items: center;
  border-left: 1px dashed rgb(0 0 0 /0.25);
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 50%;
  min-height: fit-content;
  text-align: center;
}
.Scheduler .picker.mid {
  border-left: none;
  border-top: 1px dashed rgb(0 0 0 /0.25);
  max-width: unset;
  padding-top: 10px;
  width: 100%;
}
.Scheduler .picker.mid * {
  max-width: 90vw;
}
.Scheduler .picker ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
  width: 95% !important;
}
.Scheduler .picker ul button {
  background-color: #e2e6ed;
  border: none;
  border-radius: 10px;
  height: 4vh;
  margin: 5px;
  min-width: 25% !important;
}
.Scheduler .picker ul button:hover {
  background-color: #00539b;
  color: #fff;
  cursor: pointer;
}
.Scheduler .picker ul button.active {
  background-color: #00539b;
  color: #fff;
  font-weight: 700;
}
.Scheduler .save-button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  margin: 5%;
  margin-bottom: 2vh;
  max-height: 40px;
  min-height: 40px;
  padding: 8px 10px 10px 8px;
  text-align: center;
  width: 80%;
}
.Scheduler .save-button:hover,
.Scheduler .confirm-button:hover {
  background-color: #012169;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}
.Scheduler .confirm {
  align-items: center;
  margin-top: 20px;
  max-width: 80%;
  text-align: center;
}
.Scheduler .confirm p {
  margin: 0 auto;
}
.Scheduler .confirmation-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1vh;
}
.Scheduler .confirm-button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  margin: 5%;
  max-height: 40px;
  min-height: 40px;
  padding: 8px 10px 10px 8px;
  text-align: center;
  width: 80%;
}
.Scheduler .cancel-button {
  background: #c84e00;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  margin: 5%;
  max-height: 40px;
  min-height: 40px;
  padding: 8px 10px 10px 8px;
  text-align: center;
  width: 80%;
}
.Scheduler .cancel-button:hover {
  background-color: #8f3700;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}
.Scheduler .confirm h3 {
  margin-top: 0;
}
.Scheduler .dropdowns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.Scheduler .dd-sort,
.dd-hour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 10px;
}
.Scheduler .dropdowns select {
  background-color: #e2e6ed;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgb(0 0 0 /0.5);
  margin-top: 5px;
  padding: 10px;
}
.Scheduler .dropdowns select > * {
  font-family: "Open Sans", sans-serif !important;
}
.Scheduler .sched-msg,
.Scheduler .confirm {
  max-width: 80% !important;
}
.Scheduler .deletion-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding: 10px;
}
.Scheduler .delete-button,
.Scheduler .no-keep {
  background-color: #c84e00;
}
.Scheduler .delete-button:hover,
.Scheduler .no-keep:hover {
  background-color: #8f3700;
}
.Scheduler .yes-delete {
  background-color: #a1b70d;
}
.Scheduler .yes-delete:hover {
  background-color: #758609;
}
.Scheduler .deletion-buttons button {
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  height: 40px;
  padding: 8px 10px 10px 8px;
  width: 30%;
}
.Scheduler .deletion-buttons button:hover {
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}
.Scheduler #sched-ts {
  border-bottom: 3px dashed #a1b70d;
  color: #00539b;
  font-weight: 700 !important;
  padding-bottom: 5px;
}

/* MUI DateCalendar Styling */
/* Applies to AC Calendar as well */
.MuiPickersCalendarHeader-label * {
  color: #fff !important;
  font-family: "Open Sans" !important;
}
.MuiDayCalendar-header {
  background-color: #00539b;
  border-radius: 10px 10px 0 0;
}
.MuiPickersSlideTransition-root {
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0 0 5px 2px rgb(0 0 0 /0.25) !important;
}
.MuiDayCalendar-header * {
  color: #fff !important;
  font-weight: 700 !important;
}
