.ContactUs {
  background-color: #e2e6ed;
  font-family: "Open Sans", sans-serif !important;
  height: 100%;
  min-height: 83.15vh;
  padding: 10px;
}
.ContactUs .postsubmit #ask {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  text-align: center;
}
.ContactUs h2 {
  color: #005587;
  font-size: 1.5em;
  font-weight: 700;
  margin: 0 auto;
  max-width: 75vw;
  text-align: center;
}
#contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3vh auto;
  max-width: 75vw;
}
#contactForm form {
  margin: 3vh auto;
  max-width: 400px;
}
#contactForm #message-label {
  color: rgb(0 0 0 /.6);
  display: block;
  font-weight: 700;
  padding: 10px 10px 5px 0;
  width: fit-content;
}
#contactForm textarea {
  resize: none;

  align-self: center;
  border-color: rgb(0 0 0 /.25);
  border-radius: 10px;
  display: block;
  font-family: "Open Sans", sans-serif !important;
  margin-bottom: 15px;
  /* wrap word might be gone */
  padding: 10px 16px;
  resize: vertical;
  width: 75vw;
}
#contactForm #contact-message {
  height: 100px;
  
  resize: none;

}
#contactForm .default-text {
  color: rgb(0 0 0 /.5);
}
#contactForm .user-text {
  color: #000;
}
#resub {
  font-size: 1.25em;
  min-height: fit-content;
  min-width: fit-content;
  padding: 10px 18px 10px 18px;
}
#resub:hover {
  padding: 12px 16px 8px 20px;
}
.contact-button {
  align-self: center;
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /.5);
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
  margin: 20px 0;
  padding: 10px 18px 10px 18px;
  text-align: center;
}
.contact-button:hover {
  background: #012169;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /.5);
  padding: 12px 16px 8px 20px;
  cursor: pointer;
}
.thank-you-text {
  color: #012169;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
}
.ContactUs #contact-label {
  color: #005587;
}
.ContactUs .postsubmit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Horizontally center the content */
  min-height: 83.15vh;
}