.AboutUs {
  align-items: center;
  background-color: #e2e6ed;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.AboutUs * {
  font-family: "Open Sans", sans-serif;
}
.AboutUs h3,
.contact-table tc:not(:first-child) {
  text-align: center;
}
.AboutUs h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  padding: 20px 0;
  text-align: center;
  width: fit-content;
}
.toggleable h2 {
  color: #000;
  cursor: pointer;
  width: 80%;
}
.toggleable.active h2 {
  border-bottom: 3px double #339898;
  color: #339898;
  cursor: pointer;
  margin-bottom: 30px;
}
.AboutUs .mail-staff {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;
  width: 75vw;
}
.AboutUs.mobile .mail-staff,
.AboutUs.mobile .dev-team,
.AboutUs.mobile .sponsors {
  width: 70vw;
}
.AboutUs.mobile .mail-staff:hover,
.AboutUs.mobile .dev-team:hover,
.AboutUs.mobile .sponsors:hover {
  cursor: pointer;
}
.AboutUs .members {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 20px 20px;
  padding-left: 3vw;
  padding-right: 3vw;
}
.AboutUs .dev-team {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 75vw;
}
.AboutUs.mobile .staff-member .person-info {
  min-width: 200px;
  width: 20vw;
}
.AboutUs #student-team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 80%;
}
.AboutUs.mobile .dev-team #student-team {
  align-self: center;
  margin-bottom: 10vh;
  max-width: 80%;
  padding: 0;
}
.AboutUs #team-photo {
  border-radius: 10px;
  height: auto;
  min-width: 200px;
  width: 100;
}
.AboutUs #team-desc {
  min-width: 200px;
}
.AboutUs #team-leads {
  background-position: 50%;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.AboutUs .sponsors {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 10vh;
  width: 75vw;
}
.AboutUs #logos {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.AboutUs #logos img {
  height: auto;
  margin-bottom: max(20px, 5vh);
  min-width: 100px;
  width: 25%;
}
.contact-table {
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.contact-table tr {
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
.contact-table td {
  padding: 10px 15px;
}
.contact-table th {
  background-color: #f2f2f2;
  font-weight: 700;
  padding: 10px 15px;
}
.contact-table tc {
  padding: 2px 5px;
}
.contact-table tc:first-child {
  text-align: left;
}
.mission-statement p:first-of-type {
  margin-top: 0;
}
.mission-statement p::first-line {
  text-indent: 0;
}
#project-button {
  align-self: center;
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 3vh;
  margin-top: 10px;
  min-height: fit-content;
  padding: 8px 12px 12px 8px;
  width: fit-content;
}
#project-button:hover {
  background: #012169;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /.5);
  cursor: pointer;
  padding: 10px;
}
#toggled {
  display: block !important;
  box-sizing: border-box;
  margin: 0 5vw 5vw 5vw;
  width: 80%;
}
