.Package {
display: flex;
align-items: center;
border-radius: 10px;
background-color: #00539B;
width: 90%;
margin: 0 auto;
text-align: left;
padding: 6px;
margin-bottom: 1vh;
min-height: 6vh;
overflow-wrap: break-word;
word-break: break-word;
}
.package-info {
  flex: 1; /* Take up the available space to push the copy-container to the right */
}
.Package p {
  margin: 0;
  color: #fff;
}
.copy-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
}
.copy-icon {
  color: white;
  cursor: pointer;
  border-radius: 15%;
  transition: background-color 0.2s, color 0.2s;
  padding: 1px;
  margin-right: -4px;
}

.copy-icon:hover {
  background-color: white;
  color: #00539B;
}

.copied-message {
  font-size: 10px;
  color: #00539B !important;
}

.copied-message.copy {
  color: #fff !important;
}