/* MAIN SHUB styles */
.SHub {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 6px rgb(0 0 0 /0.25);
  margin-right: 5vw;
  min-height: 50vh;
  overflow: hidden;
  width: 28vw;
}
.SHub * {
  color: #fff;
}
.SHub.mobile {
  width: 75vw;
  margin: auto 0 5vh auto;
}

.SHub h2 {
  background-color: #993399;
  font-family: "Open Sans";
  font-size: 1.25em;
  font-weight: 700;
  margin: 0;
  padding: 1.5vh;
  text-align: center;
}
.SHub.mobile {
  margin: 0 auto;
  margin-bottom: 5vh;
  width: 75vw;
}

.SHubContent {
  background-color: #fff;
  height: 100%;
  padding-top: 5px;
}
.SHubContent p {
  color: #949494;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
}

/* SHUB new pickup styles */
.SHubContent button {
  background: #00539b;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0px 1.5px rgb(4 45 55 /0.5);
  box-sizing: border-box;
  font-size: 1em;
  font-weight: 700;
  min-height: 40px;
  margin-top: 10px;
  padding: 8px 10px 10px 8px;
  width: 60%;
}
.SHubContent button:hover {
  background: #012169;
  box-shadow: inset 3px 6px 0px 1.5px rgba(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}

.SHubContent .no-packages {
  font-style: italic;
  margin-top: 1.5vh;
  text-align: center;
  color: rgb(0 0 0 /0.5);
  font-family: 'Open Sans';
}

.no-scheduled-pickup {
  border-radius: 10px;
  color: rgba(0 0 0 /0.5);
  font-style: italic;
  margin: 10px auto;
  min-height: 6vh;
  overflow-wrap: normal;
  padding: 6px;
  width: 80%;
}

.scheduled-pickup {
  align-items: center;
  background-color: #e2e6ed;
  border: 3px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1vh auto 0 auto;
  min-height: 6vh;
  overflow-wrap: normal;
  padding: 6px;
  text-align: left;
  width: 80%;
}
.scheduled-pickup p {
  align-self: flex-start;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 10px;
  padding: 10px;
}
.scheduled-pickup:hover {
  border-color: #00539b;
  cursor: pointer;
}

.sched-ops {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-evenly;
  margin: 10px;
  width: 90%;
}

.deletion-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
}

.scheduled-pickup .delete-button,
.scheduled-pickup .no-keep {
  background-color: #c84e00;
}
.scheduled-pickup .delete-button:hover,
.scheduled-pickup .no-keep:hover {
  background-color: #8f3700;
}

.scheduled-pickup .yes-delete {
  background-color: #a1b70d;
}

.scheduled-pickup .yes-delete:hover {
  background-color: #758609;
}

.scheduled-pickup #sched-ts {
  border-bottom: 3px solid #a1b70d;
  color: #00539b;
  font-weight: 700;
  line-height: 1.8;
  padding-bottom: 5px;
}

.loading {
  padding-top: 20px;
}

/* disclaimer and terms styles */
.disclaimer {
  color: rgb(0 0 0 /0.5);
  font-weight: 400;
  margin: 20px auto 10px auto;
  text-align: center;
  width: 85%;
}
.terms {
  color: rgb(0 0 0 /0.5);
  font-weight: 400;
  margin: 20px auto 10px auto;
  text-align: left;
  width: 80%;
}

.dialogTitle, .dialogContent {
  font-family: 'Open Sans', sans-serif !important;
}
