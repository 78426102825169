body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.dynamic-input {
  font-family: "Open Sans";
  overflow-y: hidden;
  resize: none;
  width: 95%;
}
/* mui calendar overlappin' */
.MuiDateCalendar-root {
  max-width: 300px;
}
/* loading */
.wheel {
  transform: translate(0%, 400%);
}
.lds-ring {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;
}
.lds-ring div {
  animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border: 8px solid #fff;
  border-color: #fff transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 64px;
  margin: 8px;
  position: absolute;
  width: 64px;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loading ends */
.AdminHub {
  align-items: center;
  background-color: #e2e6ed;
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  padding-bottom: 4vh;
}
.AH-flex-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif !important;
  gap: min(40px, 5vw);
  justify-content: center;
  max-width: 100vw !important;
}
.AH-flex-wrapper.mid,
.AH-flex-wrapper.mobile {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.AH-flex-wrapper.mobile > * {
  width: 90vw;
}
.AdminHub .inner-msgmod {
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px rgb(0 0 0 /0.25);
  box-sizing: border-box;
  font-family: "Open Sans";
  justify-content: center;
  min-height: 142px;
  min-width: fit-content;
  padding: 15px;
}
.AdminHub .inner-msgmod .msg-header {
  font-size: 1em;
  font-weight: 700;
  margin-left: 5px;
  text-align: left;
}
.AdminHub .inner-msgmod button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  height: fit-content;
  margin-bottom: 0;
  margin-top: 10px;
  padding: 8px 10px 10px 8px;
  width: 60%;
}
.AdminHub .inner-msgmod button:hover,
.AH-col2 .submit-button:hover {
  background: #012169;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}
.AdminHub .line-stat .LineStatus h2 {
  font-size: 1em;
  font-weight: 400;
  margin-left: 5%;
  padding: 5px;
}
.AH-col1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  min-width: 20vw;
  text-align: left;
}
.AH-col1.mid {
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 95%;
  width: 70%;
}
.AH-col1.mid > * {
  box-sizing: border-box;
  flex-basis: calc(47.5% - 10px);
  flex-grow: 1;
  margin: 5px;
  min-width: fit-content;
}
.AdminHub h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  margin: 0;
  padding-left: 15px;
  white-space: normal;
}
.AH-col1.mobile {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 5vh;
}
.AH-col1.mobile > * {
  width: 100%;
}
.AdminHub .inner-msgmod {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.AdminHub .inner-msgmod > * {
  margin: 5px;
}
.AdminHub .inner-msgmod textarea {
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 3px rgb(0 0 0 /0.5);
  flex-grow: 1;
  min-height: 30px;
  min-width: 80%;
}
.AdminHub .inner-msgmod button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  height: fit-content;
  margin-bottom: 0;
  padding: 8px 10px 10px 8px;
  width: 60%;
}
.AdminHub.mobile .message,
.AdminHub.mobile .line-stat {
  min-height: unset;
}
.AdminHub .admin-title {
  color: #65676b;
  font-family: "Open Sans";
  font-weight: 700;
  margin: 0 auto;
  padding: 0.5vh 1.5vh;
  text-align: center;
}
.AdminHub .previews {
  background-color: #ced3dc;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgb(0 0 0 /0.5);
  box-sizing: border-box;
  margin-top: 0.5vh;
  padding: 10px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 20px auto;
  text-align: center;
}
th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}
tr:nth-child(2n) {
  background-color: #f9f9f9;
}
.AC-col2 {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 /0.25);
  display: flex;
  flex-direction: column;
  flex-grow: 1 0;
  height: fit-content;
  min-width: 50vw;
  overflow: hidden;
}
.AH-col2.mid,
.AH-col3.mid {
  flex-grow: 1;
  width: 80%;
}
.AH-col3.mid .scd-dl {
  margin: 0 auto;
  width: 40%;
}
.cmp-title {
  background-color: #00539b;
  border-radius: 20px 20px 0 0;
  color: #fff;
  font-weight: 700;
  margin: 0;
  padding: 20px;
  text-align: center;
}
.ACH .AC-selectors {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  margin: auto;
  min-height: 80px;
  padding: 5px;
  width: 90%;
}
#SP-slot {
  width: 100%;
}
#SP-slot button {
  width: 30%;
}
.AH-col2 .AC-slot,
.AH-col3 .AC-slot,
.AH-col3 .AC-timeslot,
.AH-col3 .AC-count {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  justify-content: center;
  margin: 5px;
}
.AH-col2 .AC-slot {
  justify-content: space-evenly;
}
.AH-col3 .AC-timeslot,
.AH-col3 .AC-count {
  justify-content: center;
}
.AC-slot button {
  background-color: #e2e6ed;
  border: none;
  border-radius: 10px;
  font-family: "Open Sans";
  font-weight: 700;
  min-height: 65px;
  padding: 8px 10px 10px 8px;
  text-align: center;
  width: 100%;
  box-shadow: inset -3px -6px 0 1.5px rgba(70, 74, 75, 0.5);
}
.AC-slot button:hover {
  background-color: #a6b3c9;
  cursor: pointer;
  font-weight: 900;
  padding: 10px 8px 8px 10px;
}

.AH-col1,
.AH-col3 {
  width: 20vw;
}
.AH-col2 {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px rgb(0 0 0 /0.25);
  height: fit-content;
  width: 50vw;
}
.AH-col2 .no-date-selec {
  text-align: center;
  width: 100%;
}
.AH-col3 .AC-count:active {
  border: 1px solid grey;
}
.AH-col2 .AC-selectors * label {
  padding: 5px;
}
.AH-col2 .AC-selectors * select {
  border-radius: 10px;
  min-height: 30px;
}
.AH-col2 h2 {
  height: auto;
  margin: 0;
  text-align: center;
}

/* BUTTON styles */
.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  width: 80%;
}
.submit-button {
  background: #00539b;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  height: fit-content;
  margin-bottom: 0;
  min-width: fit-content;
  padding: 8px 10px 10px 8px;
  width: 30%;
}
.cancel-button {
  background: #c84e00;
  border: none;
  border-radius: 10px;
  box-shadow: inset -3px -6px 0 1.5px rgb(4 45 55 /0.5);
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  height: fit-content;
  margin-bottom: 0;
  min-width: fit-content;
  padding: 8px 10px 10px 8px;
  width: 30%;
}
.cancel-button:hover {
  background: #8f3700;
  box-shadow: inset 3px 6px 0 1.5px rgb(0 0 0 /0.5);
  cursor: pointer;
  padding: 10px 8px 8px 10px;
}

/* EVENT styles */
.error {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  margin: 100px;
  text-align: center;
}
.load {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  margin: 100px;
  text-align: center;
}

/* ADMINCAL styles */
.AdminHub.admin-cal {
  flex: 1;
}
.AdminHub .admin-cal * {
  max-width: 100% !important;
}