@import "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap";
.Message {
  font-family: "Open Sans";
  margin-top: 5px;
  white-space: pre-wrap;
}
.status-box-m {
  align-items: center;
  background-color: #ffd960;
  border-radius: 16px;
  box-shadow: 0 0 6px rgb(0 0 0 /.25);
  display: flex;
  min-height: 58px;
  height: fit-content;
  margin: 5px;
  min-width: fit-content;
  /* overflow-wrap: normal; */
  padding: 10px 10px;
  /* overflow-y: scroll; */

}
.update {
  color: #59595F;
  font-size: .75em;
  margin-top: 5px;
}
.status-box p {
  color: #000;
  font-size: 1em;
  font-weight: 400;
  margin: auto 0;
  margin-left: 2px;
  text-align: left;
}
.LineStatus.mobile .status-box {
  height: fit-content;
  padding: 15px;
}
.status-selector {
  align-items: center;
  display: flex;
}
.mod-wrapper {
  background-color: rgb(233, 215, 215);
  padding: 15px;
}
.Message i {
  color: #59595F;
  padding-left: 10px;
}
